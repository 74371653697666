import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import App from './App';
import { initStore } from './redux';
import './services';
import './i18n';
import amplitude from 'amplitude-js';
// import ReactGA from 'react-ga';
// import ReactPixel from 'react-facebook-pixel';

amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY, null, {includeUtm: true, includeReferrer: true, includeGclid: true});
// ReactGA.initialize(process.env.REACT_APP_GA_ID);
// ReactPixel.init(process.env.REACT_APP_FB_ID);

ReactDOM.render(
  <Provider store={initStore()}>
    <BrowserRouter>
        <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
