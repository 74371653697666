import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {Formik, Field} from 'formik';
import {cartSelector, priceSelector} from '../../ducks/cart';
import {useAuthorized, useUserInfo} from '../../ducks/auth';
import {
    saveOrderData,
    orderSelector,
    getDeliveryCost,
    resetDeliveryCost,
    toggleAboutPickupModal, checkPromo, resetDiscounts
} from '../../ducks/order';
import {ReactComponent as CloseSmallIcon} from '../../svg/close-36.svg';
import {ReactComponent as CloseIcon} from '../../svg/close-48.svg';
import {ReactComponent as ArrowLeft} from '../../svg/arrow-left-small.svg';
import {ReactComponent as UserIcon} from '../../svg/user-small.svg';
import {ReactComponent as EditIcon} from '../../svg/edit-white.svg';
import {ReactComponent as PlusSmallIcon} from '../../svg/plus-small.svg';
import {ReactComponent as ZzzIcon} from '../../svg/zzz.svg';
import {ReactComponent as ClockIcon} from '../../svg/clock.svg';
import {ReactComponent as HelpIcon} from '../../svg/help-black.svg';
import {
    getUserAddresses,
    getCurrentDay,
    getCurrentMonth,
    findAddressById,
    INTERVALS,
    DAYS,
    MONTHS_ARRAY,
    MONTHS_ARRAY_EN,
    MONTHS,
    MONTHS_EN,
    MONTHS_OBJ,
    MONTHS_OBJ_EN,
    getYears,
    checkIfKitchenIsClosed,
    checkIfDeliveryIsClosed,
    getTimePart,
    checkIfFivePercentDiscountIsActive,
    checkIfTenPercentDicsountIsActive,
    roundPrice,
    MILISEC_IN_ONE_HOUR,
    validatePickupDate, getCurrentMonthEn, getTimeRange, calcDiscountWithoutSpecialProducts, INTERVALS_NY
} from '../../utils';
import InputBlock from './../common/input-block';
import InputBlockTime from './../common/input-block-time';
import InputBlockAddress from '../common/input-block-address';
import {useTranslation} from "react-i18next";
import {useNavigate, useOutletContext} from "react-router";
import {Link, useLocation} from "react-router-dom";


function OrderPage() {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [hideChild, setHideChild]= useOutletContext();
    const [showModal, setShowModal] = useState(false);

    const isAuthorized = useAuthorized();
    const userInfo = useUserInfo();
    const userAddresses = getUserAddresses(userInfo);
    const userAddressesAmount = userAddresses.length;
    const orderData = useSelector(orderSelector);
    const deliveryCostError = orderData.errorDelivery;
    const dispatch = useDispatch();
    const years = getYears();


    const loading = orderData.loading;

    const [selectedAddressId, setAddress] = useState(-1);
    const [isPickup, toggleIsPickup] = useState(false);
    const [time, toggleTime] = useState(false);
    const [kitchenIsClosed, setKitchenIsClosed] = useState(false);
    const [pickUoTimeRange, setPickUoTimeRange] = useState('10:30-21:59');
    const [intervals, setIntervals] = useState([]);
    const [initialInterval, setInitialInterval] = useState(INTERVALS[0]);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const [pickupPoint, togglePickupPoint] = useState(99);
    const [yAddress, setYAddress] = useState('');


    const handleCloseModal = () => {
        setShowModal(false);
        setTimeout(()=> {
            navigate('/');
        }, 200)
    }

    const handleBack = () => {
        setShowModal(false);
        setTimeout(()=> {
            navigate(-1);
        }, 200)
    }

    const handleAddAddress= () => {
        setShowModal(false);
        setTimeout(()=> {
            navigate('/account/add-address');
        }, 200)
    }

    useEffect(() => {
        if (location) {
            setTimeout(()=> {
                setShowModal(true);
            }, 200)
        }
    }, [location]);

    useEffect(() => {
        if (hideChild) {
            handleCloseModal();
            setHideChild(false);
        }
    }, [hideChild]);

    const toggleTypeFunc = async (val) => {
        toggleIsPickup(val);
    };
    const toggleTimeFunc = (val) => {
        toggleTime(val);
    }
    const togglePickupPointFunc = (val) => {
        togglePickupPoint(val);
        dispatch(getDeliveryCost({isPickup: isPickup, pickupPoint: val, address: yAddress}));
    }

    const setAddressFunc = (value) => {
        setAddress(value);
        const address = findAddressById(userAddresses, value);
        setYAddress(address.street);
    };
    const setYAddressFunc = (value) => {
        setYAddress(value);
    };

    const handleEditAddress = (idx) => {
        setShowModal(false);
        setTimeout(()=> {
            navigate('/account/edit-address/' + idx);
        }, 200)
    }

    const deliveryCost = orderData.deliveryCost;
    const deliveryMin = orderData.deliveryMin;
    const itemsPrice = useSelector(priceSelector);
    const totalPrice = itemsPrice + deliveryCost;
    const discountPrice = totalPrice - orderData.discount;
    const haveDiscount = !!orderData.discount;

    const hideDeliveryTime = time;

    const deliveryIsClosed = checkIfDeliveryIsClosed();
    const deliveryIsUnavailable = !isPickup && itemsPrice < 700;

    const usingSavedAddress = isAuthorized && userAddresses.length;
    const needToValidateAddressInputs = !isPickup && !usingSavedAddress;
    const nearestTimeDisabled = (deliveryIsClosed && !isPickup) || kitchenIsClosed;

    useEffect(() => {
        if (orderData.address !== '') {
            setYAddress(orderData.address);
        }
    }, [orderData.address]);

    useEffect(() => {
        if (!time) {
            dispatch(resetDiscounts());
        }
    }, [isPickup, time]);

    useEffect(() => {
        if (yAddress || isPickup) {
            dispatch(getDeliveryCost({
                isPickup: isPickup,
                pickupPoint: pickupPoint,
                address: yAddress}));
        } else {
            setYAddress('');
            dispatch(resetDeliveryCost());
        }
    }, [showModal, isPickup, yAddress]);

    useEffect(() => {
        if (nearestTimeDisabled) {
            toggleTimeFunc(true);
        } else {
            toggleTimeFunc(false);
        }
    }, [nearestTimeDisabled]);

    useEffect(() => {

        const { range, _intervals } = currentIntervals();
        setInitialInterval(_intervals[0]);

        if (isPickup) {
            if (range) {
                setPickUoTimeRange(range);
                setKitchenIsClosed(checkIfKitchenIsClosed(range));
            } else {
                switch (pickupPoint) {
                    case 0:
                        setPickUoTimeRange('11:15-21:30');
                        setKitchenIsClosed(checkIfKitchenIsClosed('11:00-21:30'));
                        break;
                    case 1:
                        setPickUoTimeRange('11:15-21:30');
                        setKitchenIsClosed(checkIfKitchenIsClosed('11:00-21:30'));
                        break;
                    case 2:
                        setPickUoTimeRange('11:15-21:30');
                        setKitchenIsClosed(checkIfKitchenIsClosed('11:00-21:30'));
                        break;
                    default:
                        setPickUoTimeRange('11:15-21:30');
                        setKitchenIsClosed(checkIfKitchenIsClosed('11:00-21:30'));
                        break;
                }
            }
        } else {
            let finalIntervals;
            if (range) {
                setKitchenIsClosed(checkIfKitchenIsClosed(range));
                finalIntervals = _intervals.map(v => <option key={v} value={v}>{v}</option>);
            } else {
                setKitchenIsClosed(checkIfKitchenIsClosed('11:00-21:30'));
                finalIntervals = INTERVALS_NY.map(v => <option key={v} value={v}>{v}</option>);
            }

            setIntervals(finalIntervals);
        }
    }, [isPickup, pickupPoint, selectedDate]);

    const validate = values => {
        const errors = {};

        if (needToValidateAddressInputs) {
            if (!values.address) {
                errors.address = t('formValidation.required');
            }

            if (!values.entrance) {
                errors.entrance = t('formValidation.required');
            }

            if (!values.floor) {
                errors.floor = t('formValidation.required');
            }
        }

        if (time) {
            const timeToCheck = getTimePart(values.time);

            const timeValid = /(_)/g.exec(timeToCheck);
            if (timeValid !== null) {
                errors.time = t('formValidation.incorrectDateTime');
            } else {
                const month = i18n.language === 'en' ? MONTHS_EN[values.month] : MONTHS[values.month];
                let dateStr = values.year + '-' + month + '-' + values.day + 'T' + timeToCheck + ':00';

                const selectedTimestamp = Date.parse(dateStr);
                const now = +new Date() + (MILISEC_IN_ONE_HOUR / 2);
                const _selectedDate = new Date(selectedTimestamp);
                setSelectedDate(_selectedDate);

                if (now > selectedTimestamp) {
                    errors.time = t('formValidation.incorrectDateTimeToEarly');
                } else if (isPickup && timeToCheck) {
                    if (!validatePickupDate(selectedTimestamp, timeToCheck, pickUoTimeRange)) {
                        errors.time = t('formValidation.incorrectDateTime');
                    }
                } else if (timeToCheck && !intervals.length) {
                    errors.time = i18n.language === 'en' ? 'holiday' : 'выходной';
                }
            }

            dispatch(checkPromo({
                userId: userInfo ? userInfo.id : '',
                code: "",
                params: {
                    day: values.day,
                    month: i18n.language === 'en' ? MONTHS_EN[values.month] : MONTHS[values.month],
                    year: values.year,
                    time: values.time,
                    delivery_type: isPickup? 'pickup' : 'courier',
                }
            }));
        }

        return errors;
    }

    const currentIntervals = () => {
        let range = null;
        let _intervals;
        let intervalStartIdx = 0;
        let intervalStopIdx = 0;

        const dateMonth = selectedDate.getMonth();
        const dateSelected = selectedDate.getDate();
        const dateWeekday = selectedDate.getDay();


        /* Delivery interval Mon-Fri */
        if (dateWeekday >= 1 && dateWeekday <= 5) {
            range = '10:00-21:30';  /* kitchen */
            intervalStartIdx = 0;   /* interval start index */
            intervalStopIdx = -1;   /* interval end index */
        }

        /* Delivery interval Sat-Sun */
        if (dateWeekday === 6 || dateWeekday === 0) {
            range = '11:00-21:30';  /* kitchen */
            intervalStartIdx = 1;   /* interval start index */
            intervalStopIdx = -1;   /* interval end index */
        }

        /* 31.12 */
        if (dateMonth === 11 && dateSelected === 31) {
            range = '10:00-16:30';
            intervalStartIdx = 2;
            intervalStopIdx = 4;
        }

        /* 01.01 */
        if (dateMonth === 0 && dateSelected === 1) {
            range = i18n.language === 'en' ? 'holiday' : 'выходной';
            intervalStartIdx = 99;
            intervalStopIdx = 0;
        }

        /* 02.01 */
        if (dateMonth === 0 && dateSelected === 2) {
            range = '14:00-21:30';
            intervalStartIdx = 4;
            intervalStopIdx = 0;
        }

        /* 03.01 */
        if (dateMonth === 0 && dateSelected === 3) {
            range = '12:00-21:30';
            intervalStartIdx = 2;
            intervalStopIdx = 0;
        }

        /* 04.01 */
        if (dateMonth === 0 && dateSelected === 4) {
            range = '12:00-21:30';
            intervalStartIdx = 2;
            intervalStopIdx = 0;
        }

        /* 05.01 */
        if (dateMonth === 0 && dateSelected === 5) {
            range = '12:00-21:30';
            intervalStartIdx = 2;
            intervalStopIdx = 0;
        }

        /* 21.02 */
        if (dateMonth === 2 && dateSelected === 7) {
            range = '14:00-21:30';
            intervalStartIdx = 2;
            intervalStopIdx = 0;
        }

        if (intervalStopIdx) {
            _intervals = INTERVALS_NY.slice(intervalStartIdx, intervalStopIdx);
        } else {
            _intervals = INTERVALS_NY.slice(intervalStartIdx);
        }

        /* Hide not available intervals, for today only */
        const d = new Date();
        if (dateSelected === d.getDate() && dateMonth === d.getMonth()) {
            const nowWithDelay = d.getHours() * 60 + d.getMinutes() + 90; /* 90 minutes delay */
            _intervals = _intervals.filter((v) => {
                const { start } = getTimeRange(v);

                return nowWithDelay < start;
            })
        }

        return { range, _intervals }
    }

    const initialValues = {
        address: orderData.address,
        entrance: orderData.entrance,
        intercom: orderData.intercom,
        floor: orderData.floor,
        appartment: orderData.appartment,
        comment: orderData.comment,
        // date: orderData.date,
        day: orderData.day || getCurrentDay(),
        month: i18n.language === 'en' ? orderData.month ? MONTHS_OBJ_EN[orderData.month] : getCurrentMonthEn() : orderData.month ? MONTHS_OBJ[orderData.month] : getCurrentMonth(),
        year: orderData.year || years[0],
        time: orderData.time || INTERVALS_NY[4],
        type: isPickup
    };

    const handleSubmit = (values) => {
        let order = {
            address: '',
            entrance: '',
            intercom: '',
            floor: '',
            appartment: '',
            comment: '',
            // date: '',
            day: '',
            month: '',
            year: '',
            time: '',
            pickup: '',
            referer: localStorage.getItem('referer')
        };

        if (!isPickup) {
            if (!isAuthorized || !userAddressesAmount) {
                // get address from form
                order = {
                    ...values,
                    // date: '',
                    day: '',
                    month: '',
                    year: '',
                    time: '',
                    pickup: ''
                }
            } else {
                // add from saved address
                const selectedAddress = findAddressById(userAddresses, selectedAddressId);

                order = {
                    address: selectedAddress.street,
                    entrance: selectedAddress.podezd,
                    floor: selectedAddress.etazh
                }

                if (selectedAddress.kvartira) {
                    order.appartment = selectedAddress.kvartira;
                }

                if (selectedAddress['kod-domofona']) {
                    order.intercom = selectedAddress['kod-domofona'];
                }

                if (selectedAddress.comment) {
                    order.comment = selectedAddress.comment;
                }
            }
        } else {
            if (pickupPoint === 1) {
                order.pickup = 'depo';
            } else if (pickupPoint === 2) {
                order.pickup = 'gruzinka';
            } else if (pickupPoint === 3) {
                order.pickup = 'mitnaya';
            } else if (pickupPoint === 4) {
                order.pickup = 'pokrovka';
            } else if (pickupPoint === 5) {
                order.pickup = 'ermolaevskiy';
            }
        }

        if (time) {
            order.time = values.time;
            // order.date = values.date;
            order.day = values.day;
            order.month = i18n.language === 'en' ? MONTHS_EN[values.month] : MONTHS[values.month];
            order.year = values.year;
        } else {
            order.time = '';
            order.day = '';
            order.month = '';
            order.year = '';
        }

        dispatch(saveOrderData(order));
        navigate('/payment');
    }

    const orderClassnames = classNames({
        'order modal': true,
        'modal--visible': showModal
    });

    const headerClassnames = classNames({
        'modal-header modal-header--no-vertical-center': true,
        'modal-header--visible': showModal
    });

    const submitClassnames = classNames({
        'modal__submit': true,
        'modal__submit--visible': showModal
    });

    const typeClassnames = classNames({
        'order__type': true,
        'order__type--right': isPickup,
    });

    const typeCourierClassnames = classNames({
        'order__type-courier': true,
        'order__type-courier--active': !isPickup,
        'effect': true
    });

    const typePickupClassnames = classNames({
        'order__type-pickup': true,
        'order__type-pickup--active': isPickup,
        'effect': true
    });

    const timeNearClassnames = classNames({
        'order__time-block': true,
        'order__time-block--active': !time,
        'order__time-block--disabled': nearestTimeDisabled,
        'effect4': true
    });

    const timeAfterClassnames = classNames({
        'order__time-block': true,
        'order__time-block--active': time,
        'effect4': true
    });

    const pickupOneClassnames = classNames({
        'order__text': true,
        'order__text--active': pickupPoint === 1,
    });

    const pickupTwoClassnames = classNames({
        'order__text': true,
        'order__text--active': pickupPoint === 2,
    });

    const pickupThreeClassnames = classNames({
        'order__text': true,
        'order__text--active': pickupPoint === 3,
    });

    const pickupFourClassnames = classNames({
        'order__text': true,
        'order__text--active': pickupPoint === 4,
    });

    const pickupFiveClassnames = classNames({
        'order__text': true,
        'order__text--active': pickupPoint === 5,
    });

    const addressesClasses = classNames({
        'order__addresses': true,
        'order__addresses--hidden': isPickup || (!isPickup && !usingSavedAddress),
    });

    const inputsClasses = classNames({
        'order__inputs-wrapper': true,
        'order__inputs-wrapper--hidden': isPickup || (!isPickup && usingSavedAddress) || deliveryIsUnavailable,
    });

    const pickupClasses = classNames({
        'order__pickup-wrapper': true,
        'order__pickup-wrapper--hidden': !isPickup,
    });

    const deliveryVariantClasses = classNames({
        'order__time': true,
        'order--time--hidden': deliveryIsUnavailable
    });

    const deliveryTimeClasses = classNames({
        'base-text-white base-text-with-place': true,
        'not-visible': hideDeliveryTime
    });

    let deliveryString =
        <span className='order__delivery-block small-text-grey'>
      <ClockIcon title='Время доставки'/>
            {t('page.order.deliveryTimeCost', {min: deliveryMin, cost: deliveryCost})}
    </span>

    if (hideDeliveryTime) {
        deliveryString =
            <span className='order__delivery-block small-text-grey'>
        {`${deliveryCost} ₽`}
      </span>
    }

    if (deliveryCostError && !isPickup && yAddress) {
        deliveryString =
            <span className='order__delivery-block small-text-red'>{t('page.order.shippingNotAvailable')}</span>
    }

    return (
        <div className={orderClassnames}>
            <div className={headerClassnames}>
                <div className='modal-header__back'>
                    <button type='button' onClick={ handleBack } className='base-header effect'>
                        <ArrowLeft/> {t('page.order.delivery')}
                    </button>
                    {deliveryString}
                </div>
                <div className='modal-header__mobile-close'>
                    <button type='button' className='effect' onClick={ handleCloseModal }>
                        <CloseSmallIcon title='Закрыть'/>
                    </button>
                </div>
                <div className='modal-header__close'>
                    <button type='button' className='effect' onClick={ handleCloseModal }>
                        <CloseIcon title='Закрыть'/> {t('page.close')}
                    </button>
                </div>
            </div>
            <Formik
                initialValues={initialValues}
                validate={validate}
                onSubmit={handleSubmit}
                validateOnMount
                enableReinitialize>
                {
                    props => {
                        const {touched, isValid, errors, values} = props;
                        const submitDisabled = (!isValid && time) || loading || deliveryCostError ||
                            deliveryIsUnavailable || (isPickup && pickupPoint === 99) ||
                            (!isPickup && isAuthorized && (selectedAddressId < 0 && yAddress === ''));

                        const timeErrorClassnames = classNames({
                            'input-block__error': true,
                            'input-block__error--visible': errors.time && touched.time,
                        });

                        const priceClassnames = classNames({
                            'text-line-through': haveDiscount
                        });

                        return (
                            <form className='modal__wrapper' onSubmit={props.handleSubmit}>
                                <div className={typeClassnames}>
                                    <div className={typeCourierClassnames} onClick={() => toggleTypeFunc(false)}>
                                        {t('page.order.deliveryCourier')}
                                    </div>
                                    <div className={typePickupClassnames} onClick={() => toggleTypeFunc(true)}>
                                        {t('page.order.deliveryPickup')}
                                    </div>
                                </div>
                                {
                                    isAuthorized ? null :
                                        <div className='order__login'>
                                            <div className='order__login-text'>
                                                {t('page.auth.wasSingUp')}
                                            </div>
                                            <Link to={'/login'} className='order__login-btn effect3 no-flick'>
                                                <UserIcon/> <span>{t('page.auth.enter')}</span>
                                            </Link>
                                        </div>
                                }
                                {
                                    !deliveryIsClosed ? null :
                                        <div className='order__zzz base-text'>
                                            <ZzzIcon/> <span>{t('page.order.deliveryNotWork')}</span>
                                        </div>
                                }
                                {
                                    !kitchenIsClosed ? null :
                                        <div className='order__zzz base-text'>
                                            <ZzzIcon/> <span>{t('page.order.kitchenClosed')}</span>
                                        </div>
                                }
                                {
                                    !deliveryIsUnavailable ? null :
                                        <div className='order__zzz base-text'>
                                            <span>{t('page.order.minimumDelivery')}</span>
                                        </div>
                                }

                                <div className={addressesClasses}>
                                    {
                                        userAddresses.map((a, i) => {
                                            const {street, etazh, kvartira, podezd, comment} = a.data;
                                            const entranceStr = podezd ? t('page.account.entrance') + podezd + ' / ' : '';
                                            const commentStr = comment ? ` / ${comment}` : '';
                                            const intercomStr = a.data['kod-domofona'] ? ' / ' + t('page.account.intercom') + a.data['kod-domofona'] : '';
                                            const appartmentStr = kvartira ? ' / ' + t('page.account.apartment') + kvartira : '';
                                            const addressClassnames = classNames({
                                                'order__address': true,
                                                'order__address--active': selectedAddressId === a.id,
                                                'effect4': true
                                            });

                                            return (
                                                <div key={i} className={addressClassnames}
                                                     onClick={() => setAddressFunc(a.id)}>
                                                    <div className='order__address-top base-text-bold'>
                                                        {`${street}`}
                                                    </div>
                                                    <div className='base-text'>
                                                        <div>
                                                            {t('page.account.addressString', {
                                                                entrance: entranceStr,
                                                                floor: etazh,
                                                                apartment: appartmentStr,
                                                                intercom: intercomStr,
                                                                comment: commentStr
                                                            })}
                                                        </div>
                                                    </div>
                                                    <span onClick={() => handleEditAddress(a.id)}
                                                          className='order__edit-info-icon effect3'><EditIcon/></span>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className='order__ingridients'>
                                        <span onClick={ handleAddAddress } className='order__edit-btn effect3 no-flick'><span
                                            className='cart__edit-icon'><PlusSmallIcon/></span> {t('page.account.addAddress')}</span>
                                    </div>
                                </div>

                                <div className={inputsClasses}>
                                    <div className='order__half-width-input'>
                                        {
                                            isPickup ? null :
                                                <InputBlockAddress
                                                    name='address'
                                                    label={t('page.order.address')}
                                                    onAddressChange={ setYAddressFunc }
                                                    // onValidationFail={}
                                                />
                                        }
                                    </div>
                                    <div className='order__small-inputs'>
                                        <InputBlock
                                            name='entrance'
                                            label={t('page.order.entrance')}
                                        />
                                        <InputBlock
                                            name='intercom'
                                            label={t('page.order.intercom')}
                                        />
                                        <InputBlock
                                            name='floor'
                                            label={t('page.order.floor')}
                                        />
                                        <InputBlock
                                            name='appartment'
                                            label={t('page.order.apartment')}
                                        />
                                    </div>
                                    <div className='order__half-width-input'>
                                        <InputBlock
                                            name='comment'
                                            label={t('page.order.comment')}
                                        />
                                    </div>
                                </div>
                                <div className={pickupClasses}>
                                    {/* Депо 1 */}
                                    <div className='order__pickup effect4' onClick={() => togglePickupPointFunc(1)}>
                                        <div className='order__pickup-map order__pickup-map--one'></div>
                                        <div className={pickupOneClassnames}>{t('page.order.pickupDepo')}</div>
                                    </div>
                                    {/* Грузинка 2 */}
                                    <div className='order__pickup effect4' onClick={() => togglePickupPointFunc(2)}>
                                        <div className='order__pickup-map order__pickup-map--two'></div>
                                        <div className={pickupTwoClassnames}>{t('page.order.pickupGruzinka')}</div>
                                    </div>
                                    {/* Мытная 3 */}
                                    <div className='order__pickup effect4' onClick={() => togglePickupPointFunc(3)}>
                                        <div className='order__pickup-map order__pickup-map--three'></div>
                                        <div className={pickupThreeClassnames}>{t('page.order.pickupMitnaya')}</div>
                                    </div>
                                    {/* Покровка 4 */}
                                    <div className='order__pickup effect4' onClick={() => togglePickupPointFunc(4)}>
                                        <div className='order__pickup-map order__pickup-map--four'></div>
                                        <div className={pickupFourClassnames}>{t('page.order.pickupPokrovka')}</div>
                                    </div>
                                    {/* Ермолаевсиий 5 */}
                                    <div className='order__pickup effect4' onClick={() => togglePickupPointFunc(5)}>
                                        <div className='order__pickup-map order__pickup-map--five'></div>
                                        <div className={pickupFiveClassnames}>{t('page.order.pickupErmolaevskiy')}</div>
                                    </div>
                                </div>
                                <div className={deliveryVariantClasses}>
                                    <div className='order__header-second base-header'>
                                        {
                                            isPickup ?
                                                <span>{t('page.order.selectReadyTime')}</span> :
                                                <span>{t('page.order.selectDeliveryTime')}</span>
                                        }
                                    </div>
                                    <div className='order__time-wrapper'>
                                        <div onClick={nearestTimeDisabled ? null : () => toggleTimeFunc(false)}
                                             className={timeNearClassnames}>
                                            {t('page.order.soonAsPossible')}
                                        </div>
                                        <div onClick={() => toggleTimeFunc(true)} className={timeAfterClassnames}>
                                            {t('page.order.selectTime')}
                                        </div>
                                    </div>
                                </div>
                                {
                                    !time ? null :
                                        <div className='order__additional-time'>
                                            <div className='input-block'>
                                                <div className='input-block__label'>
                                                    <label htmlFor='date'>{t('page.order.date')}</label>
                                                </div>
                                                <div className={classNames({
                                                    'input-block__input': true,
                                                    'input-block__input--grey': true,
                                                    'input-block__input--multiple-select': true,
                                                    'input-block__input--error': props.errors.time && props.touched.time,
                                                })}>
                                                    <Field
                                                        component='select'
                                                        id='date'
                                                        name='day'
                                                    >
                                                        {
                                                            DAYS.map((d) => <option key={`day${d}`}
                                                                                    value={d}>{d}</option>)
                                                        }
                                                    </Field>
                                                    <Field
                                                        component='select'
                                                        name='month'>
                                                        {
                                                            i18n.language === 'en' ?
                                                                MONTHS_ARRAY_EN.map((d) => <option key={`month${d}`}
                                                                                                   value={d}>{d}</option>) :
                                                                MONTHS_ARRAY.map((d) => <option key={`month${d}`}
                                                                                                value={d}>{d}</option>)
                                                        }
                                                    </Field>
                                                    <Field
                                                        component='select'
                                                        name='year'>
                                                        {
                                                            years.map((d) => <option key={d} value={d}>{d}</option>)
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className='input-block'>
                                                <div className='input-block__label'>
                                                    {
                                                        isPickup ?
                                                            <label
                                                                htmlFor='time'>{t('page.order.time')} {pickUoTimeRange}</label> :
                                                            <label htmlFor='time'>{t('page.order.time')}</label>
                                                    }
                                                </div>
                                                <div className={classNames({
                                                    'input-block__input': true,
                                                    'input-block__input--grey': true,
                                                    'input-block__input--error': props.errors.time && props.touched.time,
                                                })}>
                                                    {
                                                        isPickup ?
                                                            <InputBlockTime
                                                                name='time'>
                                                            </InputBlockTime> :
                                                            <Field
                                                                component='select'
                                                                id='time'
                                                                name='time'
                                                                // value={values.time}
                                                            >
                                                                <option value='не выбрано'>не выбрано</option>
                                                                {
                                                                    intervals
                                                                }
                                                            </Field>
                                                    }
                                                </div>
                                                <span className={timeErrorClassnames}>{errors.time}</span>
                                            </div>
                                        </div>
                                }
                                <div className={submitClassnames}>
                                    <button type='submit' disabled={submitDisabled}
                                            className='button button--multiple-children effect2'>
                                        <span
                                            className={deliveryTimeClasses}>{t('page.order.min', {minutes: deliveryMin})}</span>
                                        <span>{t('page.order.makeOrder')}</span>
                                        <span className='base-text-white'>
                                          <span className={priceClassnames}>{totalPrice} ₽</span>
                                            {haveDiscount ? <span>{`${roundPrice(discountPrice)} ₽`}</span> : null}
                                        </span>
                                    </button>
                                </div>
                            </form>
                        )
                    }
                }
            </Formik>
        </div>
    )
}

export default OrderPage;
